var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('v-card',{attrs:{"color":"primary","tile":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{staticClass:"card_image",attrs:{"src":require("../../assets/logoWhite.svg"),"alt":"","height":"30"}})]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{staticClass:"text_field_container",attrs:{"height":_vm.returnHeight,"rounded":"lg","width":_vm.returnWidth}},[_c('h4',{staticClass:"mb-8"},[_vm._v("Welcome Back!")]),_c('ValidationObserver',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.loginUser($event)}}},[_c('ValidationProvider',{attrs:{"placeholder":"Enter Your Phonenumber","rules":"required|phonenumber:0|min:11|max:11","name":"phonenumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors.length > 0)?_c('span',{attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('p',[_vm._v("Email or Phone Number")]),_c('v-text-field',{attrs:{"outlined":"","color":"primary"},model:{value:(_vm.phonenumber),callback:function ($$v) {_vm.phonenumber=$$v},expression:"phonenumber"}})]}}])}),_c('ValidationProvider',{attrs:{"placeholder":"Enter Your Password","name":"password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors.length > 0)?_c('span',{attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('p',[_vm._v("Password")]),_c('v-text-field',{attrs:{"append-icon":_vm.value ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value ? 'password' : 'text',"outlined":"","color":"primary"},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])}),_c('a',{class:[
                _vm.$vuetify.breakpoint.smAndDown
                  ? _vm.forgot_password_mobile
                  : _vm.forgot_password_web ]},[_vm._v("Forgot Password?")]),_c('v-btn',{staticClass:"auth_btn",attrs:{"color":"primary","type":"submit","depressed":"","loading":_vm.loading}},[_vm._v(" LOG IN ")]),_c('v-row',{staticClass:"mt-8",attrs:{"align":"center","justify":"center"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Already have an account?")]),_c('span',{staticClass:"ml-2 register_text",on:{"click":function($event){$event.preventDefault();return _vm.register($event)}}},[_vm._v("Register")])])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('Modal',{attrs:{"header":_vm.header,"body":_vm.body},on:{"closeModal":_vm.closeModalScreen}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }