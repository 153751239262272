<template>
  <div class="login">
    <v-card color="primary" tile>
      <v-row align="center" justify="center">
        <img
          src="../../assets/logoWhite.svg"
          alt=""
          height="30"
          class="card_image"
        />
      </v-row>
      <v-row align="center" justify="center">
        <v-card
          class="text_field_container"
          :height="returnHeight"
          rounded="lg"
          :width="returnWidth"
        >
          <h4 class="mb-8">Welcome Back!</h4>

          <ValidationObserver>
            <v-form ref="form" @submit.prevent="loginUser">
              <ValidationProvider
                placeholder="Enter Your Phonenumber"
                rules="required|phonenumber:0|min:11|max:11"
                v-slot="{ errors }"
                name="phonenumber"
              >
                <span id="error" v-if="errors.length > 0">{{ errors[0] }}</span>
                <p>Email or Phone Number</p>
                <v-text-field
                  v-model="phonenumber"
                  outlined
                  color="primary"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                placeholder="Enter Your Password"
                name="password"
                rules="required|min:6"
                v-slot="{ errors }"
              >
                <span id="error" v-if="errors.length > 0">{{ errors[0] }}</span>
                <p>Password</p>
                <v-text-field
                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value = !value)"
                  :type="value ? 'password' : 'text'"
                  v-model="password"
                  outlined
                  color="primary"
                ></v-text-field>
              </ValidationProvider>
              <a
                :class="[
                  $vuetify.breakpoint.smAndDown
                    ? forgot_password_mobile
                    : forgot_password_web,
                ]"
                >Forgot Password?</a
              >

              <v-btn
                color="primary"
                type="submit"
                class="auth_btn"
                depressed
                :loading="loading"
              >
                LOG IN
              </v-btn>
              <v-row align="center" justify="center" class="mt-8">
                <p class="mb-0">Already have an account?</p>
                <span class="ml-2 register_text" @click.prevent="register"
                  >Register</span
                ></v-row
              >
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-row>
    </v-card>
    <v-dialog v-model="dialog" persistent max-width="400">
      <Modal @closeModal="closeModalScreen" :header="header" :body="body" />
    </v-dialog>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { login } from "../services/auth-services";
import Modal from "@/ui/modal/Modal.vue";
import { mapMutations } from "vuex";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Modal,
  },

  data() {
    return {
      value: true,
      phonenumber: "",
      password: "",
      loading: false,
      header: "",
      body: "",
      dialog: false,
      forgot_password_mobile: "forgot_password_mobile",
      forgot_password_web: "forgot_password_web",
    };
  },
  computed: {
    returnWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100vw";
      } else {
        return "30vw";
      }
    },
    returnHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "88vh";
      } else {
        return "60vh";
      }
    },
  },
  methods: {
    ...mapMutations("agent", ["updateUserDetails"]),
    register() {
      this.$router.push("/register");
    },
    async loginUser() {
      this.loading = true;
      let user = {
        phone: this.phonenumber,
        password: this.password,
      };
      await login(user).then(
        (res) => {
          this.$store.commit("snackbar/SHOW_SNACKBAR", {
            message: "Welcome",
            success: true,
          });
          console.log(res);
          this.updateUserDetails(res);
          if (res.role === "SuperAdmin") {
            this.$router.push("/admin/agents");
          } else {
            this.$router.push("/agent");
          }
        },
        (err) => {
          this.$store.commit("snackbar/SHOW_SNACKBAR", {
            message: "Kindly Check Your Login Details",
            success: false,
          });
          this.loading = false;

          this.header = "User Details Incorrect";
          this.body = "Please try Again ";
          console.log(err);
          this.clearFields();
        }
      );
    },
    closeModalScreen() {
      this.dialog = false;
    },

    clearFields() {
      this.phonenumber = "";

      this.password = "";
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 1px;
}
.forgot_password_mobile {
  float: right;
  color: #272253;
  font-weight: 600;
  cursor: pointer;
}
.forgot_password_web {
  float: right;
  color: #272253;
  font-weight: 600;
  cursor: pointer;
}
.auth_btn {
  position: relative;
  height: 3rem !important;
  width: 100%;
  top: 1rem;
}
.register_text {
  cursor: pointer;
  font-weight: 600;
}
.card_image {
  position: relative;
  top: 2rem;
}
#error {
  font-size: 14px !important;
  color: red !important;
}

.create_account_btn {
  position: fixed;
  left: 40%;
}
.text_field_container {
  padding: 30px 40px;
  overflow-y: auto;
  font-weight: 500;
  color: #272253;
  font-size: 13px;
  margin-bottom: 2px !important;
}

.text_field_container h4 {
  text-align: center;
}
.login {
  height: 100vh;
}
.login >>> .v-card {
  height: -webkit-fill-available;
  box-shadow: 1px 0px 25px 0px rgba(50, 50, 50, 0.2) !important;
}
.login >>> .text_field_container {
  position: relative;
  top: 5rem;
}
.login >>> .left_column {
  height: 500px !important;
}
.login >>> .right_column {
  background-color: var(--bg-color);
}
.login
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  background: #f7f9fc;
  font-size: 13px;
  margin-bottom: 2rem;
  font-weight: 600;
}
.login >>> fieldset {
  border: unset !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000 !important;
}
</style>